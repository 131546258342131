/* @import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap"); */

*
{
    margin: 0;
    padding: 0;
}

body
{
  background-color: rgb(0, 0, 0);
  /* position: static; */
  overflow-y: scroll;
  overflow-x: hidden;
  font-weight: 600;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: rgb(255, 255, 255);
  user-select: none;
  -webkit-user-select: none;

}
@font-face {
  font-family: "Jost";
  src: url(/static/fonts/Aveny.woff);
  font-style: normal;
  font-weight: 600;
}

.webgl
{
  position: fixed;
  /* display: block; */
  height: 100vh;
  /* left: 50%;
  transform: translate(-50%, 0); */
  padding: 0;
  /* top: 0;
	left: 0;  */ 
  outline: none;
  
}

.scrollcontainer{
  position: absolute;
  height: 2700%;
  /* width: 100%; */
  /* touch-action: none !important; */
  top: 0;
  left: 0;
  outline: none;
  /* overflow: hidden; */
  /* -webkit-overflow-scrolling: touch; */

}

.artlogo
{
  position: fixed;
  top: 2%;
  left: 2%;
  width: 18vh;
  z-index: 9;
  cursor: pointer;

}
.verselogo
{
  position: fixed;
  top: 2%;
  left: 2%;
  width: 18vh;
  opacity: 1;
  mix-blend-mode: exclusion;

}
.vlogo
{
  position: fixed;
  top: 2%;
  left: 2%;
  width: 18vh;
  opacity: 1;
  z-index: 10;
  cursor: pointer;
  mix-blend-mode: exclusion;

}
.scrollbutton{
  position: fixed;
  bottom: 1%;
  left: 2%;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  cursor: pointer;
  z-index: 4;

}
.scroll {
  
  mix-blend-mode: exclusion;
  width: 26px;
  -webkit-animation: down 1s alternate infinite both;
  animation: down 1s alternate infinite both;
  display: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  font-size: 20px;
  white-space: nowrap;
  z-index: 4;
}

.title {
  position: fixed;
  display: flex;
  /* top: 33%; */
  bottom: 3%;
  left: 58%;
  /* width: 100%; */
  color: rgb(255, 255, 255);
  font-family: "Jost";
  font-size: 94px;

  /* height: 100%; */
  /* justify-content: center; */
  align-items: left;
  flex-direction: column;
}

.title span {
  white-space: nowrap;
  font-size: 36px;
}

.vincent {
  mix-blend-mode: exclusion;
  position: fixed;
  display: flex;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Jost";
  font-size: 14vh;
  white-space: nowrap;
  flex-direction: column;
  text-align: center;
  }
.vincent span {
  filter: blur(8px);
  /* transform: translateX(-100px); */
  opacity: 0;
  left: 50%;
  color: #fff;

}
.intro {
  position: relative;
  padding: 0 30px;
  width: 80%;
}
.intro-text {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  font-size: 10vh;
  
  justify-content: center;
  font-family: "Jost";
  color: rgb(255, 255, 255);
  
  display: flex;
  align-items: center;
  height: 100%;
  mix-blend-mode: exclusion;
  white-space: nowrap;

}

.intro-text span {
  opacity: 0;
  transform: translateY(40px);  
}

.intro-text span:nth-child(even) {
  transform: translateY(-40px);
}


@-webkit-keyframes down {
  0% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(6px);
  }
}

@keyframes down {
  0% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(6px);
  }
}

.contact-container{
  position: fixed;
  display: block;
 opacity: 0;
  z-index: 6;
  visibility: hidden;
}

.contact {
  position: fixed;
  display: block;
  top: 3%;
  z-index: 8;
  font-family: "Jost";

}
.card {
  position: fixed;
  display: block;
  z-index: 5;
  height: 120vh;
  overflow: hidden;
  width: min(100%, 777px); 
  opacity: 0;
  visibility: hidden;
  background: rgba( 0, 0, 0, 0.5 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 4.5px );
  border-radius: 10px;
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: 0 0;
}
.cardd {
  position: fixed;
  display: block;
  z-index: 10;
  width: min(100%, 777px); 
  transform: translate(-50%, -50%);
  left: 45%;
  top: 3%;
  visibility: hidden;
  opacity: 0;
  margin: 0 0;
  
}
.closecontact {
  position: fixed;
  display: block;
  top: 4%;
  right: 3%;
  cursor: pointer;
  color: #fff;
  z-index: 10;
}

.shake {
  position: fixed;
  display: flex;
  top: 33%;
  bottom: 0;
  left: 2%;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  height: 100%;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  text-transform: uppercase;
  z-index: 6;
}

.shake span {
  white-space: nowrap;
  font-size: 36px;
}
.lets {
  position: fixed;
  top: 6%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  font-size: 38px;
  justify-content: center;
  font-family: "Jost";
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 6;
}
.contactus {
  position: fixed;
  display: block;
  top: 4%;
  right: 3%;
  cursor: pointer;
  font-size: 20px;
  font-family: "Jost";
  color: #fff;
  text-transform: uppercase;
  z-index: 13;
  mix-blend-mode: exclusion;

}
